import { Component, OnInit } from '@angular/core';
import { MenuServiceService } from 'src/app/services/menu-service.service';

@Component({
  selector: 'app-c-card-footer',
  templateUrl: './c-card-footer.component.html',
  styleUrls: ['./c-card-footer.component.scss']
})
export class CCardFooterComponent implements OnInit {
  public isHome: boolean = false;
  constructor(private menuService: MenuServiceService) { }

  ngOnInit(): void {
    this.configurarFooter();
  }

  configurarFooter() {
    this.menuService.changeIsHome().subscribe(result => {
      this.isHome = result;
    });
  }
}
