import { Component, OnInit } from '@angular/core';
import { ProdutoService } from './services/produto.service';

declare  var jQuery:  any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'cardapio';

  constructor(){

  }

  ngOnInit(){
    
  }


}

