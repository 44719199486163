import { Component, OnInit } from '@angular/core';
import { MenuServiceService } from 'src/app/services/menu-service.service';
declare var jQuery: any;

@Component({
  selector: 'app-c-header',
  templateUrl: './c-header.component.html',
  styleUrls: ['./c-header.component.scss']
})
export class CHeaderComponent implements OnInit {
  public isHome: boolean = false;

  constructor(private menuService: MenuServiceService) { }

  ngOnInit(): void {
    this.configurarLoading();
  }
  
  configurarLoading() {
    this.menuService.changeIsHome().subscribe(result => {
      this.isHome = result;
    });
  }
  getClass(){
    var classList='';
    if(this.isHome){
       classList = 'banner-mobile d-none'; 
    }else{
        classList = 'banner-mobile fixed-top';
    }
    return classList;
  }
  
}
