<div *ngIf="isHome" class="carrinho">
    <div class="row">
        <div class="col-6 c1">
            <p>R$ <span>15,00</span><span> (1 item)</span></p>
        </div>
        <div class="col-6 c2">
            <a href="/carrinho">
                <p class="add-cart">Fazer Pedido</p>
            </a>
        </div>
    </div>
</div>

<div *ngIf="!isHome" class="carrinho">
    <div class="row">
        <div class="col-3 c1">
            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
        </div>
        <div class="col-9 c2">
            <a href="/carrinho">
                <p class="add-cart">ADICIONAR</p>
            </a>
        </div>
    </div>
</div>