import { Component, OnInit } from '@angular/core';
import { ProdutoService } from '../services/produto.service';
declare var jQuery: any;
@Component({
  selector: 'app-produto-detalhe',
  templateUrl: './produto-detalhe.component.html',
  styleUrls: ['./produto-detalhe.component.scss']
})
export class ProdutoDetalheComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    (function ($) {
      $('.btn-back').click(function () {
        window.history.back();
      });
    })(jQuery);
  }

}
