<app-c-header></app-c-header>
<section>
    <div class="container">
        <h5 class="titulo-carrinho">Meu Pedido</h5>
        <div class="row">
            <div class="col-10">
                <p class="titulo-item">Batatinha Crinckle?</p>
                <p>
                    <span>1x Lanche</span>
                    <span> R$ 10,00 </span>
                    <span>+ Catupiry (1x 4,50)</span>
                    <span>= 14,50</span>
                </p>
            </div>
            <div class="col-2 icone-lixo">
                <i class="fa fa-trash" aria-hidden="true"></i>
            </div>
        </div>
        <hr>
        <div class="col-12">
            <a href="/checkout">
                <button type="button" class="btn btn-success envia" style="width: 100%;"><i _ngcontent-osw-c19="" aria-hidden="true" class="fa fa-whatsapp fa-1x"></i><span style="margin-left: 5px;">Finalizar Pedido</span></button>
            </a>
        </div>
    </div>
</section>