<div [ngClass]="getClass()">
    <div class="row margem-r">
        <div class="col-2 cols-menu text-center">
            <a  *ngIf="isHome" href="/" style="color: #FFF;">
                <i class="fa fa-home fa-1x" aria-hidden="true"></i>
            </a>
            <i *ngIf="!isHome" onclick="function hi(){ window.history.back()};hi()" class="fa fa-arrow-left fa-1x btn-back" aria-hidden="true"></i>
        </div>

        <div class="col-8 cols-menu text-center">
            Burgerland
        </div>

        <div class="col-2 cols-menu text-center">
            <i class="fa fa-whatsapp fa-1x" aria-hidden="true"></i>
        </div>
    </div>
</div>