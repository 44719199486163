import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class MenuServiceService {
  private isHome = new Subject<boolean>();

  constructor() {
    this.isHome.next(false);
   }

   updateIsHome(state: boolean) {
    this.isHome.next(state);
  }

  changeIsHome(): Observable<boolean> {
    return this.isHome.asObservable();
  }
}
