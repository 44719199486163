<app-c-header></app-c-header>
<div class="banner text-center">
    <div class="c-imagem"><img src="assets/images/logo2.png" class="img-fluid logo"></div>
</div>
<div class="horario">
    <div class="c-horario">
        <p _ngcontent-kim-c16="" style="
    margin: 0 auto;
    text-align: center;
    padding-top: 25px;
">ONLINE</p>
        <p _ngcontent-kim-c16="" style="
    text-align: center;
">Funcionamento das 18h ás 22h</p>
    </div>
</div>
<nav id="nav-top" class="navbar navbar-expand-sm navbar-dark">
    <div class="container">
        <ul class="navbar-nav">

            <li *ngFor="let categoria of categorias" class="nav-item">
                <a class="nav-link" href="#{{categoria.name | lowercase }}">{{categoria.name}}</a>
              </li>

            <li class="nav-item">
                <a class="nav-link" href="#section1">Entradas</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#section2">Burguers</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#section3">Pizza</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#section4">Esfihas</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#section5">Bebidas</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#section6">Pasteis</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#section7">Porções</a>
            </li>
        </ul>
    </div>
</nav>

<div *ngFor="let categoria of categorias" class="container-fluid secao" id="{{categoria.name | lowercase }}">
    <div class="container">
        <h1>{{categoria.name}}</h1>
        <div class="row">
            <div *ngFor="let product of categoria.products" class="col-6 col-sm-3">
                <a href="/produto/{{product.id}}">
                    <div class="card">
                        <img class="card-img-top" src="{{product.image}}" alt="{{product.name}}">
                        <div class="card-body produto">
                            <h5 class="card-title">{{product.name}}</h5>
                        </div>
                    </div>
                </a>
            </div>            
        </div>
    </div>
</div>

<div id="section1" class="container-fluid secao primeira">
    <div class="container">
        <h1>Entradas</h1>
        <div class="row">
            <div class="col-6 col-sm-3">
                <a href="/produto">
                    <div class="card">
                        <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                        <div class="card-body produto">
                            <h5 class="card-title">Batata Rústica</h5>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-6 col-sm-3">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Palito</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-3">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Coxinha</h5>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-3">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Bolinha de Queijo</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="section2" class="container-fluid secao">
    <div class="container">
        <h1>Burguers</h1>
        <div class="row">
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Rústica</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Palito</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Coxinha</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Bolinha de Queijo</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="section3" class="container-fluid secao">
    <div class="container">
        <h1>Pizza</h1>
        <div class="row">
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Rústica</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Palito</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Coxinha</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Bolinha de Queijo</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="section4" class="container-fluid secao">
    <div class="container">
        <h1>Esfihas</h1>
        <div class="row">
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Rústica</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Palito</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Coxinha</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Bolinha de Queijo</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="section5" class="container-fluid secao">
    <div class="container">
        <h1>Bebidas</h1>
        <div class="row">
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Rústica</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Palito</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Coxinha</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Bolinha de Queijo</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="section6" class="container-fluid secao">
    <div class="container">
        <h1>Pasteis</h1>
        <div class="row">
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Rústica</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Palito</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Coxinha</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Bolinha de Queijo</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="section7" class="container-fluid secao">
    <div class="container">
        <h1>Porçoes</h1>
        <div class="row">
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Rústica</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Batata Palito</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Coxinha</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-6">
                <div class="card">
                    <img class="card-img-top" src="assets/images/batata.jpg" alt="Card image cap">
                    <div class="card-body produto">
                        <h5 class="card-title">Bolinha de Queijo</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-c-card-footer></app-c-card-footer>