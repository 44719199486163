<app-c-header></app-c-header>
<section>
    <div class="container">
        <h5 class="titulo-checkout">Finalizar Pedido</h5>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="nome-completo">Nome:</label>
                    <input type="text" class="form-control" id="nome-completo">
                    <label for="celular">Celular:</label>
                    <input type="number" class="form-control" id="celular">
                </div>
                <div class="form-group">
                    <label for="cep">CEP:</label>
                    <input type="number" class="form-control" id="cep">
                    <label for="rua">Rua:</label>
                    <input type="text" class="form-control" id="rua">
                </div>
            </div>

            <div class="col-4">
                <div class="form-group">
                    <label for="numero">Numero:</label>
                    <input type="number" class="form-control" id="numero">
                </div>
            </div>
            <div class="col-8">
                <div class="form-group">
                    <label for="numero">Complento:</label>
                    <input type="text" class="form-control" id="complemento">
                </div>
            </div>
            <div class="col-12">
                <label class="my-1 mr-2" for="pagamento">Pagamento</label>
                <select class="custom-select my-1 mr-sm-2" id="pagamento">
                    <option selected>Forma de Pagamento</option>
                    <option value="1">Dinheiro</option>
                    <option value="2">Cartão</option>
                </select>

            </div>

            <div class="col-12">
                <a href="https://api.whatsapp.com/send?phone=+5516991017754&text=pedido%123456">
                    <button type="button" class="btn btn-success enviar"> <i _ngcontent-osw-c19="" aria-hidden="true"
                            class="fa fa-whatsapp fa-1x"></i> Enviar Pedido</button>
                </a>
            </div>
        </div>
    </div>
</section>