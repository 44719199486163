<app-c-header></app-c-header>

<div class="container top-r">
    <div class="row">
        <div class="col-sm-12 p0">
            <img src="assets/images/batata.jpg" class="img-fluid">
        </div>
    </div>

    <section class="descricao-produto">
        <h5>Batatinha?</h5>
        <p>Batatinhas crinkle douradas.Aproximadamente 200g.</p>
    </section>

    <section class="grupos">
        <div class="g1">
            <h5>Cheddar ou Catupiry???</h5>
            <hr>
            <div class="row linha-grupo">
                <div class="desc col-7">
                    <span class="">Catupiry<span> (+ R$ 3,50)</span></span>
                </div>
                <div class="qtd col-5">
                    <i class="fa fa-minus diminuir-qtd" aria-hidden="true"></i>
                    <span class="qtd-total">1</span>
                    <i class="fa fa-plus aumentar-qtd" aria-hidden="true"></i>
                </div>
            </div>
            <div class="row linha-grupo">
                <div class="desc col-7">
                    <span class="">Cheddar melt<span> (+ R$ 3,50)</span></span>
                </div>
                <div class="qtd col-5">
                    <i class="fa fa-minus diminuir-qtd" aria-hidden="true"></i>
                    <span class="qtd-total">1</span>
                    <i class="fa fa-plus aumentar-qtd" aria-hidden="true"></i>
                </div>
            </div>
            <div class="row linha-grupo">
                <div class="desc col-7">
                    <span class="">Bacon<span> (+ R$ 3,50)</span></span>
                </div>
                <div class="qtd col-5">
                    <i class="fa fa-minus diminuir-qtd" aria-hidden="true"></i>
                    <span class="qtd-total">1</span>
                    <i class="fa fa-plus aumentar-qtd" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="g2">
            <h5>Você adora qual tempero???</h5>
            <hr>
            <div class="row linha-grupo">
                <div class="desc col-7">
                    <span class="">Lemon Pepper<span></span></span>
                </div>
                <div class="qtd col-5">
                    <i class="fa fa-minus diminuir-qtd" aria-hidden="true"></i>
                    <span class="qtd-total">1</span>
                    <i class="fa fa-plus aumentar-qtd" aria-hidden="true"></i>
                </div>
            </div>
            <div class="row linha-grupo">
                <div class="desc col-7">
                    <span class="">Páprica doce<span></span></span>
                </div>
                <div class="qtd col-5">
                    <i class="fa fa-minus diminuir-qtd" aria-hidden="true"></i>
                    <span class="qtd-total">1</span>
                    <i class="fa fa-plus aumentar-qtd" aria-hidden="true"></i>
                </div>
            </div>
            <div class="row linha-grupo">
                <div class="desc col-7">
                    <span class="">Maionese Verde<span></span></span>
                </div>
                <div class="qtd col-5">
                    <i class="fa fa-minus diminuir-qtd" aria-hidden="true"></i>
                    <span class="qtd-total">1</span>
                    <i class="fa fa-plus aumentar-qtd" aria-hidden="true"></i>
                </div>
            </div>
        </div>
    </section>
    <section class="obs">
        <h5>Tem algum pedido especial?</h5>
        <textarea id="obs" name="observacao" rows="3" class="form-control"></textarea>
    </section>
</div>

<app-c-card-footer></app-c-card-footer>