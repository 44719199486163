import { Component, OnInit, AfterViewInit, OnChanges } from '@angular/core';
import { MenuServiceService } from '../services/menu-service.service';
import { CategoriaService } from '../services/categoria.service';
import { Category } from '../models/Category';

declare var jQuery: any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit, OnChanges {

  title = 'cardapio';
  categorias: Category[];
  constructor(private menuService: MenuServiceService, private categoriaService: CategoriaService){  }
  ngOnInit() {
    (function ($) {
      $('.nav-link').click(function () {
        $('.nav-link').attr('data-click', 'true');
      });
      $(window).scroll(function () {
        if ($(this).scrollTop() > 381) {
          $('.banner-mobile').addClass("fixed-top");
          $('.banner-mobile').removeClass("d-none");
          $('#nav-top').addClass("fixed-top");
          $('#nav-top').addClass("menu-space-mobile");
          // add padding top to show content behind navbar
          $('body').css('padding-top', $('.navbar').outerHeight() + 'px');
        } else {
          $('.banner-mobile').removeClass("fixed-top");
          $('.banner-mobile').addClass("d-none");
          $('#nav-top').removeClass("menu-space-mobile");
          $('#nav-top').removeClass("fixed-top");
          // remove padding top from body
          $('body').css('padding-top', '0');
        }
        var att = $('.nav-link').attr('data-click');
        if (att != 'true') {
          configScroll();
        }else{
          $('.nav-link').attr('data-click', 'false');
        }
      });

      function configScroll() {
        if (typeof $('.active').position() !== "undefined") {
          var tamanhoTela = $(window).width();
          var posicaoAtivo = $('.active').position();
          if ((tamanhoTela * 0.5) < posicaoAtivo.left) {
            $('.navbar-nav').scrollLeft($('.navbar-nav').scrollLeft() + tamanhoTela / 2);
          } else if (posicaoAtivo.left < 0) {
            $('.navbar-nav').scrollLeft($('.navbar-nav').scrollLeft() - tamanhoTela / 2);
          }
        }
      }

    var offset = 95;

    $('.navbar li a').click(function(event) {
        event.preventDefault();
        $($(this).attr('href'))[0].scrollIntoView();
        $($(this).attr('href'))[0].scrollIntoView();
        scrollBy(0, -offset);
    });
    })(jQuery);

    setTimeout(() => {
      this.menuService.updateIsHome(true);
    }, 0);

    this.getCategory();
  }
  getCategory() {
    this.categoriaService.getCategoryById(1).subscribe((categorias: any[]) => {
      console.log(categorias);
      //this.categorias = categorias;
      setTimeout(() => {
        this.updateTabs();
      }, 50);
    });
  }

  updateTabs() {
    var offset = 95;

    (function ($) {
      $('.navbar li a').click(function(event) {
        event.preventDefault();
        $($(this).attr('href'))[0].scrollIntoView();
        scrollBy(0, -offset);
    });
    })(jQuery);
  }

  ngAfterViewInit(){
    
  }

  ngOnChanges(){
   
  }
}
